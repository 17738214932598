export default {
  getNumeration(state) {
    return state.numeration;
  },
  getListInvoices(state) {
    // Si no hay datos en el state, intentar recuperarlos del sessionStorage
    if (!state.listInvoices) {
      try {
        const sessionData = JSON.parse(sessionStorage.getItem('invoicesLists'));
        return sessionData || null;
      } catch (error) {
        console.error("Error al obtener datos de facturas del sessionStorage:", error);
        return null;
      }
    }
    return state.listInvoices;
  },
}