import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store/index'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [

    {
      path: '/login',
      name: 'login',
       component: () =>  import(/* webpackChunkName: "about" */ '../views/Login.vue'),
      meta: {requiresAuth: false, sitemap:true}
    },
    {
      path: '/home',
      name: 'home',
       component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Layout/structureLayout.vue'),
      meta: {requiresAuth: true, sitemap:true}
    },
    {
      path: '/',
      name: 'Inicio',
       component: () =>  import(/* webpackChunkName: "about" */ '../views/Inicio.vue'),
      meta: {requiresAuth: true, sitemap:true}
    },
 {
      path: '/configuracion',
      name: 'settings',
       component: () => import("../views/Configs/BusinessConfig.vue"), // import( '../views/General/settingsSoft.vue'),
      meta: {requiresAuth: true, sitemap:true}
    },
   {
   path: '/mis-datos',
   name: 'misDatos',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/General/myData.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 /**
  * Rutas de Caja
  */
 { 
   path: '/lista-de-cierres',
   name: 'listClosed',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Boxes/listClosed.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },
 {
   path: '/registrar-cajeros',
   name: 'registerCashier',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Boxes/registerCashier.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-cierre-de-caja',
   name: 'listClosedBoxes',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Boxes/cashClosingList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-cajas',
   name: 'boxesList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Boxes/boxesList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/cajas-de-usuarios',
   name: 'usersBoxes',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Boxes/usersBoxes.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/cierre-de-caja',
   name: 'closeBox',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Boxes/closeBox.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/apertura-de-caja',
   name: 'openBox',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Boxes/openingBox.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 /**
  * Fin de Caja
  */
 /**
  * Productos en Electrónica
  */
 {
   path: '/formulario-subir-archivos-actualizar-productos',
   name: 'formUploadFilesUpdateProducts',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/formUploadFilesUpdateProducts.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/productos-bonificados',
   name: 'discountedProducts',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Products/discountedProducts.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/agregar-productos-xml',
   name: 'addProductsXml',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/addProductsXml.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/nuevo-productos',
   name: 'newProducts',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/newProducts.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/registrar-producto',
   name: 'registerProduct',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/registerProduct.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/nuevo-producto-compra',
   name: 'newProductPurchase',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/newProductPurchase.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-productos-compras',
   name: 'ProductlistSell',
    component: () => import("../views/Products/listProducts.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/listProductSell.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/Sistemas-de-apartados',
   name: 'sectionSystems',
   // no tiene component
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/sectionSystems.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-productos',
   name: 'Productslist',
    component: () => import("../views/Products/listProducts.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/listProducts.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/registrar-producto-market',
   name: 'NewProductMarket',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/NewProductMarket.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-compras',
   name: 'shoppingList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/shoppingList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/pago-de-estudiantes',
   name: 'studentPayment',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/Receipts/studentPayment.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/pago-de-estudiantes',
   name: 'newShop',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Buys/BuysCreate.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-compras-pendientes',
   name: 'pendingShoppingList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Buys/pendingShoppingList.vue'),
   meta: {
     meta: { requiresAuth: true, sitemap: true }
   }
 },
 {
   path: '/lista-de-inventario-Manual',
   name: 'inventoryListManual',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/Segments/inventoryListManual.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/producto/:id/editar',
   name: 'editServices',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/EditServices.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/producto/ver/:id',
   name: 'viewServices',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/EditServices.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/Lista/proformas',
   name: 'proformasList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/proformasList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-documentos-creados',
   name: 'listDocumentsCreate',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Buys/ListsFeBuy.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-segmentos',
   name: 'listSegment',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/Segments/index.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-marcas',
   name: 'BrandList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/BrandList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-categorias',
   name: 'categoriesList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/categoriesList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/agregar-inventario-no-real',
   name: 'addNon-ActualInventory',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/addNon-ActualInventory.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/toma-fisica-de-inventario',
   name: 'physicalInventoryTaking',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/physicalInventoryTaking.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-colaboradores',
   name: 'contributorList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/contributorList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-json-enviados-por-android',
   name: 'listOfJsonSentByAndroid',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/listOfJsonSentByAndroid.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/activar-mes',
   name: 'activateMonth',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/General/RolesPermission/Tasks/activateMonth.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/convertir-respuestas-codificadas',
   name: 'convertCodedResponses',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/General/RolesPermission/Tasks/convertCodedResponses.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-estudiantes',
   name: 'studentsList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/General/RolesPermission/Tasks/studentsList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/cambio-existencias',
   name: 'changeStocks',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Boxes/changeStocks.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-cursos',
   name: 'courseList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/courseList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-estudiantes-inactivo',
   name: 'inactiveStudentList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/inactiveStudentList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 /**
  * Facturación Electrónica
  */
 {
   path: '/lista-de-documentos-electronicos',
   name: 'ListsDocuments',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/ListsDocuments.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/facturacion-electronica',
   name: 'FacturacionElectronicaCliente',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/invoicesElectronic.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/pedido-online',
   name: 'PedidoOnline',
    component: () => import("../views/Invoices/OnlineOrderSchool.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/invoicesElectronic.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/factura-electronica-market',
   name: 'electronicInvoicesMarket',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/electronicInvoicesMarket.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/tiquete-electronico-market',
   name: 'electronicTicketMarket',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/electronicTicketMarket.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/factura-electronica-Distribution',
   name: 'electronicInvoicesDistribution',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/electronicInvoicesDistribution.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/tiquete-electronico-otros',
   name: 'electronicTicketOthers',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/electronicTicketOthers.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/nuevo-cliente-de-factura-electronica',
   name: 'newCustomerElectronicInvoice',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/newCustomerElectronicInvoice.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/facturacion-rapida-proveedores',
   name: 'fastInvoicesSuppliers',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/fastInvoicesSuppliers.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/facturas-electronica-compras',
   name: 'electronicPurchasesInvoices',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/electronicPurchasesInvoices.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lists-invoices-trackings',
   name: 'listsInvoicesTrackings',
    component: () => import("../views/Invoices/listsInvoicesTrackings.vue"), // import(/* webpackChunkName: "about" */ './../views/Invoices/listsInvoicesTrackings.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-facturas-electronicas',
   name: 'electronicInvoiceList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Invoices/electronicInvoiceList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-facturas-por-usuario',
   name: 'listInvoicesUser',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/listInvoicesUser.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },

 {
   path: '/asignacion-permisos-tareas',
   name: 'assignmentPermissionsTask',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Configs/assignmentPermissionsTask.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/listas-de-usuarios',
   name: 'usersList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Configs/userLists.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-ventas',
   name: 'salesList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Customers/Receipts/salesList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-notas-de-credito',
   name: 'elistCreditNotes',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/Receipts/listCreditNotes.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/liquidar-distribuidor',
   name: 'liquidateDealer',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Distribuitors/liquidateDealer.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-recepcion-nueva',
   name: 'newReceptionList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Reception/newReceptionList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/asignacion-inventario-por-lista',
   name: 'inventoryAssignmentPerList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/inventoryAssignmentPerList .vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/asignar-inventario-por-unidad',
   name: 'allocateInventoryUnit',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Products/allocateInventoryUnit.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/liquidar-entregador',
   name: 'liquidateDelivery',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Delivery/liquidateDelivery.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-proveedor',
   name: 'providerList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/Receipts/providerList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/nuevo-proveedor',
   name: 'newProvider',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/Receipts/newProvider.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/recepcion-documentos-contabilidad',
   name: 'receptionAccountingDocuments',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Reception/receptionAccountingDocuments.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/registro-de-menu',
   name: 'registerMenu',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/General/RolesPermission/Menu/registerMenu.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/crear-titulo-menu',
   name: 'createTitleMenu',

    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/General/RolesPermission/TitleMenu/createTitleMenu.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/tiquete-electronico',
   name: 'tiquete_Electronico',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/ticketElectronic.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/registro-de-tarea',
   name: 'taskRegister',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Configs/taskRegister.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-ordenes',
   name: 'orderList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Presale/orderList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/ventas-sin-terminar',
   name: 'unfinishedSales',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Customers/Receipts/unfinishedSales.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/nuevo-empleado',
   name: 'EmployeeCreate',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/EmployeeCreate.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/create-user',
   name: 'createUser',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/General/RolesPermission/Users/createUser.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-ordenes-anuladas',
   name: 'canceledOrderList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/Receipts/canceledOrderList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-ordenes-para-entregados',
   name: 'listOrdersDeliver',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Presale/listOrderDeliver.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-ordenes-o-facturas-asignadas',
   name: 'listOrdersOrAssignedInvoices',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Customers/Receipts/listOrdersOrAssignedInvoices.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/datos-de-mi-negocio',
   name: 'dataOfMyBusiness',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/Receipts/dataOfMyBusiness.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-tarea',
   name: 'taskList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Configs/taskList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/role-create',
   name: 'createRole',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Configs/createRole.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-roles',
   name: 'roleList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Configs/roleList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/asignacion-permisos-roles',
   name: 'rolePermissionsAssignment',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Configs/rolePermissionsAssignment.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/permisos-por-grupos-de-role',
   name: 'permissionsByRoleGroups',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Configs/groupRolePermissions.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/registro-estudiantes',
   name: 'studentsRegister',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/General/RolesPermission/Users/studentsRegister.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },
 {
   path: '/registrar-nueva-cuenta',
   name: 'registerNewAccount',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Configs/registerNewAccount.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/registrolis-cursos',
   name: 'registerCourses',

    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/General/RolesPermission/Users/registerCourses.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/compra-electronico',
   name: 'compraElectronico',

    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/buysElectronic.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/notas-electronicas',
   name: 'notesElectronico',

    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Invoices/notesElectronic.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/recepcion-documentos',
   name: 'RecepcionDoc',

    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Reception/ReceptionFileXml.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/nueva-lista-de-recepcion',
   name: 'newReceptionList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Reception/newReceptionList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-compras-gastos',
   name: 'comprasGastos',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Reception/CreateFastBuys.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 /***
  * Sección de clientes
  */
 {
   path: '/registro-cliente-factura-electronico',
   name: 'RegistroClientesFE',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/RegisterFacturaElectronic.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-cuentas-clientes',
   name: 'listAccountCustomer',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Manteniments/listAccountCustomer.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/registro-cliente-tiquete-electronico',
   name: 'RegistroClientesTE',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/RegisterTiqueteElectronic.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 // {
 //     path: '/locacion-de-clientes',
 //     name: '',
 //      component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ ''),
 //     meta: {
 //          meta: {requiresAuth: true, sitemap:true}

 //     }
 // },
 {
   path: '/lista-de-clientes',
   name: 'ListaDeClientes',
    component: () =>  import('./../views/Customers/CustomersLists.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/cliente-editar',
   name: 'edicion_cliente',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/EditCustomer.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-recibos',
   name: 'ReceiptsList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/Receipts/ReceiptsList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },

 /***
  * Rutas de los reportes
  */
 {
   path: '/reportes-de-ventas',
   name: 'SaleReport',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Reports/SaleReport.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/reporte-venta-Distribucion',
   name: 'saleDistributionReport',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Distribuitors/saleDistributionReport.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/nuevo-recibo',
   name: 'newReceipt',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Customers/Receipts/newReceipt.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-recibos-educacion',
   name: 'educationReceiptList',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Customers/Receipts/educationReceiptList.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/Reportes-de-estudiantes',
   name: 'studentsReport',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Reports/studentsReport.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/reporte-venta-directa',
   name: 'directSaleReport',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ '../views/Reports/directSaleReport.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/reportes-productos',
   name: 'reportProductsService',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Reports/reportProductsService.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/reportes-de-proveedores',
   name: 'supplierReport',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Reports/supplierReport.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/reporte-de-carga-distribuidor',
   name: 'distributorLoadReport',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Reports/distributorLoadReport.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/reporte-de-colaboradores',
   name: 'collaboratorsReport',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Reports/collaboratorsReport.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/reportes-de-clientes',
   name: 'CustomerReport',
    component: () => import("../views/Boxes/boxesList.vue"), // import(/* webpackChunkName: "about" */ './../views/Reports/CustomersReport.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 /***
  * Configuración
  * Aquí inicia los menus para la parte de configuración y generación de rutas generales como el menus
  */
 {
   path: '/permisos/:id/menus/:sysconf/usuarios',
   name: 'permisos-menus-usuarios',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/PermissionMenu.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/asignacion-menus-roles',
   name: 'assignacion-menus-role',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/GroupMenuRole.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/activar-menus-usuarios',
   name: 'activar-menus-usuarios',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/PermissionMenu.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/asignacion-rol-a-usuarios',
   name: 'permisos-rol-usuarios',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/PermissionRole.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-menus',
   name: 'lista-menu',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Menu/index.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/menu/:id/editar',
   name: 'editMenu',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Menu/edit.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-usuarios',
   name: 'lista-usuarios',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Users/index.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/registro-de-usuarios',
   name: 'registro-usuarios',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Users/create.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/registro-de-usuarios-admin',
   name: 'registro-usuarios-admin',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Users/createAdmin.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-tareas',
   name: 'lista-task',
    component: () => import("../views/Boxes/boxesList.vue"), // import('./../views/General/RolesPermission/Tasks/index.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/lista-de-roles',
   name: 'lista-roles',
    component: () => import("../views/Boxes/boxesList.vue"), // import('./../views/General/RolesPermission/Roles/index.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/role/:id/editar',
   name: 'roleEdition',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Roles/edit.vue'),
   meta: {requiresAuth: true, sitemap:true}
 },
 {
   path: '/registro-de-rol',
   name: 'registro-roles',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Roles/create.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },
    /**
     * no registrados
     */
 {
   path: '/lista-tareas',
   name: 'lista-tareas',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Roles/create.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },

 {
   path: '/locacion-de-clientes',
   name: 'locacion-de-clientes',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Roles/create.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },
 {
   path: '/reportes-de-venta',
   name: 'reportes-de-venta',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Roles/create.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },
 {
   path: '/report.products.services',
   name: 'report.products.services',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Roles/create.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },
 {
   path: '/nuevo-compra',
   name: 'nuevo-compra',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Roles/create.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },
 {
   path: '/lista-de-recepcion-nueva',
   name: 'lista-de-recepcion-nueva',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Roles/create.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },
 {
   path: '/lista-de-recepcion-nueva',
   name: 'market-tiquete',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Roles/create.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },
 {
   path: '/market-tiquete',
   name: 'market-tiquete',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Roles/create.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },
 {
   path: '/create-role',
   name: 'create-role',
    component: () => import("../views/Boxes/boxesList.vue"), // import('../views/General/RolesPermission/Roles/create.vue'),
   meta: { requiresAuth: true, sitemap: true }
 },
    /**
     * Contabilidad
     */
    {
      path: '/reportes-contabilidad',
      name: 'report-account',
       component: () =>  import('../views/Reports/AccountReports.vue'),
      meta: { requiresAuth: true, sitemap: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.dispatch('tryVeryLogin')
   console.log(store.state.auth.loggedIn,'--- to '+to.matched.some(route => route.meta.requiresAuth ),from)
  if (to.matched.some(route => route.meta.requiresAuth )) {
    if (!store.state.auth.loggedIn) {
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  }
});
export default router
