export default {
  setNumeration(state,payload){

    state.numeration = payload.numeration;

  },
  setListInvoices(state,payload){

    state.listInvoices = payload.listInvoices;

  }
};
