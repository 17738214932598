import axios from "axios";

export default {

    async fetchProductsAction(context) {
        let data = JSON.parse(sessionStorage.getItem('userSession'));
        let product = data.selectProducts;
        context.commit('setProducts', {
            dataProducts: product,
        });
    },

    async consultApiProduct(context, payload) {
        let products = JSON.parse(sessionStorage.getItem('products'));
        let response = products.filter(product => product.id === payload);
        return response;
    }
}