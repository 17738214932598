import { createStore, createLogger } from 'vuex';

import authModule from './modules/auth/index.js';
import customer from './modules/Customers/index.js';
import product from './modules/Products/index.js';
import sysconf from './modules/sysconf/index.js';
import rolesPermission from './modules/RolesPermission/index.js';
import users from './modules/user/index.js';
import invoices from './modules/Invoices/index.js';
import directSale from './modules/DirectSales/index.js';
import seatings from './modules/Account/Seatings/index.js';

export default createStore({
  modules: {
    auth: authModule,
    customer: customer,
    product: product,
    sysconf: sysconf,
    rolesPermission: rolesPermission,
    users:users,
    invoices:invoices,
    seatings:seatings,
    directSale:directSale
  },
  plugins:[createLogger()]
})
