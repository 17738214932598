import axios from "axios";

export default {

    async fetchCustomersAction (context) {
        let data = JSON.parse(sessionStorage.getItem('userSession'));
    let customers= data.selectCustomers;
        context.commit('setCustomers', {
            customers: customers,
        });
    },
    async fetchCustomerAction (context, payload) {
        let data = JSON.parse(sessionStorage.getItem('customer'));
        let customer = data.filter(customer => customer.id === payload);

        context.commit('setCustomer', {
            customer: customer[0],
        });
    }
}