
import axios from 'axios'
;

export default {

  async storeUser(context, payload) {
    const url = `/user/store-user`;

      const response = await axios.post(url, payload);


      return response.data;

  },
  async listUser(context, payload) {
    const url = `/data-de-usuarios`;
    try {
      const response = await axios.get(url, payload);

      sessionStorage.setItem('listUsers', JSON.stringify(response.data));


      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async tryListUser(context) {

    try {

    const data =  sessionStorage.getItem('user');
      context.commit('setListUsers', {
        listsUsers: data
      });

      return data;
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },

  async SessionUser(context) {
    const url = `/user/user-session`;

      const response = await axios.get(url);
      console.log(response.data.message);
      sessionStorage.setItem('userSession', JSON.stringify(response.data));
      context.commit('setUser', {
        user: response.data
      });
      context.commit('setMessageLogin', {
        message: response.data.message
      });
      return response.data

  },
  async trySessionUser(context) {

    try {
      const data = JSON.parse(sessionStorage.getItem('userSession'));
      context.commit('setUser', {
        user: data
      });
      return data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
/*   async getUser(context, payload) {
    const url = `/login`
    console.log('url', url)
    
    try {
      const response = await axios.post(url, payload)
      console.log('response', response)
      const { data: { access_token, expires_in, refresh_token } } = response
      // this.axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`

      const expiresIn = +expires_in * 1000
      const expirationDate = new Date().getTime() + expiresIn

      sessionStorage.setItem('token', access_token)
      sessionStorage.setItem('tokenExpiration', expirationDate)
      sessionStorage.setItem('tokenRefresh', refresh_token)

      timer = setTimeout(function() {
        context.dispatch('autoLogout')
      }, expiresIn)

      context.commit('setToken', {
        token: access_token,
        tokenExpiration: expirationDate,
        tokenRefresh: refresh_token
      })

      return response
    } catch (error) {
      axios.defaults.headers.common.Authorization = null
      // this.axios.defaults.headers.common.Authorization = null
      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }   
  },

  tryLogin(context) {
    const token = sessionStorage.getItem('token')
    const tokenExpiration = sessionStorage.getItem('tokenExpiration')
    const tokenRefresh = sessionStorage.getItem('tokenRefresh')

    const expiresIn = +tokenExpiration - new Date().getTime()

    if (expiresIn < 0) {
      return
    }

    timer = setTimeout(function() {
      context.dispatch('autoLogout')
    }, expiresIn)

    if (token) {
      context.commit('setToken', {
        token: token,
        tokenRefresh: tokenRefresh
      })
    }
  },

  logout(context) {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('tokenExpiration')
    sessionStorage.removeItem('tokenRefresh')

    clearTimeout(timer)

    context.commit('setToken', {
      token: null
    })
  },
  
  autoLogout(context) {
    context.dispatch('logout')
    context.commit('setAutoLogout')
  } */
}
