export default {
  setCustomers(state, payload) {

    state.customers = payload.customers;
  },
  setCustomer(state, payload) {

    state.customer = payload.customer;
  },

}