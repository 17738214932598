import axios from 'axios'

export default {
  async sysconfs(context) {
    const url = `/sysconfig/bussines`;
    try {
      const response = await axios.get(url);
      console.log(response.data.status);
      sessionStorage.setItem('sysconf', JSON.stringify(response.data));
      localStorage.setItem('sysconf', JSON.stringify(response.data));
      context.commit('setSysconf', {
        sysconf: response.data
      });

      return response
    } catch (error) {
      const errorLogin = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      if (errorLogin.message === 'Request failed with status code 401') {
       this.dispatch('autoLogout');
      }

      throw errorLogin
    }
  }, 
  async infoConfig(context) {
    const url = `/sysconfig/info-config`;
    try {
      const response = await axios.get(url);
      console.log(response.data.status);
      sessionStorage.setItem('info_sysconf', JSON.stringify(response.data));
      context.commit('setInfoSysconf', {
        sysconfInfo: response.data
      });

      return response
    } catch (error) {
      const errorLogin = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      if (errorLogin.message === 'Request failed with status code 401') {
       this.dispatch('autoLogout');
      }

      throw errorLogin
    }
  },
  async trySysconfs(context) {
    const sysconf = JSON.parse(sessionStorage.getItem('sysconf'));

    if (sysconf) {
      await context.commit('setSysconf', {
        sysconf: sysconf
      });
    }
  },
  async listCities(context) {
    const url = `/lists-neighborhood`;
    try {
      const response = await axios.get(url);
      console.log(response.data.status);
      sessionStorage.setItem('lists_neighborhood', JSON.stringify(response.data));
      context.commit('setNeighborhood', {
        neighborhood: response.data
      });

      return response
    } catch (error) {
      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      if (errorLogin.message === 'Request failed with status code 401') {
        this.dispatch('autoLogout');
      }

      throw errorLogin
    }
  },
  async tryListCities(context) {
    const neighborhood = JSON.parse(sessionStorage.getItem('lists_neighborhood'));

    if (neighborhood) {
     await context.commit('setNeighborhood', {
        neighborhood: neighborhood
      });
    }
  },
  getfileAccountUpload(context) {
    axios.get("/sysconfig_bussines").then((response) => {
      self.sysconfig_bussines = response.data;
    });
  },
  dataSysconfAction(context) {
    axios.get("/sysconfig_bussines").then((response) => {
      localStorage.setItem('sysconfData', JSON.stringify(response.data));
      context.commit('sysconfMuttation', {
        sysconf: response.data
      })
    });
  },
  tryDataSysconfAction(context) {
    const sysconf = localStorage.getItem('sysconfData');
    context.commit('sysconfMuttation', {
      sysconf: JSON.parse(sysconf)
    })
  },
  async updateBusinessData(context, businessData) {
    const url = `/sysconfig/update-new-account/${businessData.get('id')}`;
    try {
      // Configurar las cabeceras para FormData
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      
      const response = await axios.put(url, businessData, config);
      console.log('Datos actualizados:', response.data);
      
      // Actualizar los datos en el almacenamiento local
      if (response.data) {
        sessionStorage.setItem('sysconf', JSON.stringify(response.data));
        localStorage.setItem('sysconf', JSON.stringify(response.data));
        
        // Actualizar el estado
        context.commit('setSysconf', {
          sysconf: response.data
        });
      }
      
      return response;
    } catch (error) {
      console.error('Error al actualizar los datos:', error);
      
      const errorUpdate = new Error(
        error.response?.data?.message || error.message || 'Error al actualizar los datos de la empresa.'
      );
      
      if (error.response && error.response.status === 401) {
        this.dispatch('autoLogout');
      }
      
      throw errorUpdate;
    }
  },
  async updateConsecutive(context, consecutive) {
    const url = `/sysconfig/update-consecutive/${consecutive.id}`;
    try {
      const response = await axios.put(url, consecutive);
      console.log('Consecutivo actualizado:', response.data);
      
      // Obtener los datos actualizados de la empresa
      await context.dispatch('sysconfs');
      
      return response;
    } catch (error) {
      console.error('Error al actualizar el consecutivo:', error);
      
      const errorUpdate = new Error(
        error.message || 'Error al actualizar el consecutivo.'
      );
      
      if (error.response && error.response.status === 401) {
        this.dispatch('autoLogout');
      }
      
      throw errorUpdate;
    }
  }
} 