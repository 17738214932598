import axios from 'axios'
import { userHelper } from '@/store/modules/auth/actions';

export default {
    /**
     *
     * @param state
     * @param payload
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    async postInvoiceAction(state, payload) {
        const url = '/invoices/upload-orders-presale-web';
        try {
            const response   = await axios.post(url, payload);
            return response
        } catch (error) {
            console.log(error)
            return error;
        }
    },

    async listInvoicesAction(context) {
        const url = `/invoices/data-invoices-trackings`;
        try {
            const response = await axios.get(url);
            if (response.data) {
                //console.log("Respuesta de API de facturas:", response.data);
                sessionStorage.setItem('invoicesLists', JSON.stringify(response.data));
                context.commit('setListInvoices', {
                    invoicesLists: response.data,
                });
                return response;
            } else {
                console.error("La respuesta de la API no contiene datos");
                return null;
            }
        } catch (error) {
            console.error("Error en listInvoicesAction:", error);
            return null;
        }
    },
    async sendListInvoices(context, payload) {
        const url = payload;
        try {
            const response = await axios.get(url);
            sessionStorage.setItem('invoicesLists', JSON.stringify(response.data));
            context.commit('setListInvoices', {
                invoicesLists: response.data,
            });
            return response
        } catch (error) {
            console.log(error)
        }
    },
    async tryListInvoice(context) {
        try {
            const invoices = JSON.parse(sessionStorage.getItem('invoicesLists'));
            if (invoices) {
                await context.commit('setInvoiceListsPos', {
                    invoicesLists: invoices,
                });
            }
            return invoices;
        } catch (error) {
            console.log(error)
        }
    },
    async listReceptions(context) {
        const url = `/receptions/data-reception`;
        try {
            const response = await axios.get(url);
            sessionStorage.setItem('reception_lists', JSON.stringify(response.data));
            context.commit('setReceptionsLists', {
                receptionLists: response.data,
            });
            return response
        } catch (error) {
            console.log(error)
        }
    },
    async tryListReceptions(context) {
        try {
            const reception = JSON.parse(sessionStorage.getItem('reception_lists'));
            console.log(reception);
            await context.commit('setReceptionsLists', {
                receptionLists: reception,
            });
            return reception;
        } catch (error) {
            console.log(error)
        }
    },

    async pagesInvoices(context, payload) {
        try {
            const response = await axios.get(payload);

            sessionStorage.setItem('invoicesLists', JSON.stringify(response.data));
            await context.commit('setInvoiceListsPos', {
                customersLists: response.data
            });
            return response
        } catch (error) {
            console.log(error)

        }
    },
    async fetchNumerationAction(context) {
        const numerationStr = sessionStorage.getItem('numeration');
        // Parseamos la numeración - quitamos las comillas JSON
        const numeration = JSON.parse(numerationStr);

        // Usamos el helper para obtener los datos del usuario
        const user = userHelper.getUserData();

        // Si no tenemos usuario en el helper, intentamos obtenerlo del sessionStorage
        const userData = user || JSON.parse(sessionStorage.getItem('user'));

        // Convertimos numeration a número para poder sumar correctamente
        const numerationNum = parseInt(numeration, 10);
        const nextNum = numerationNum + 1;

        let formNumeration;
        switch (nextNum.toString().length) {
            case 1:
                formNumeration = userData.id + '02-00000' + nextNum;
                break;
            case 2:
                formNumeration = userData.id + '02-0000' + nextNum;
                break;
            case 3:
                formNumeration = userData.id + '02-000' + nextNum;
                break;
            case 4:
                formNumeration = userData.id + '02-00' + nextNum;
                break;
            case 5:
                formNumeration = userData.id + '02-0' + nextNum;
                break;
            default:
                formNumeration = userData.id + '02-' + nextNum;
        }

        // Actualizamos el valor en sessionStorage para futuras referencias
     //

        await context.commit('setNumeration', {
            numeration: formNumeration
        });
    },
    async updateNumerationAction(context, payload) {
        // Si el payload es una cadena completa con formato userId-numeration
        if (typeof payload === 'string' && payload.includes('-')) {
            let parts = payload.split('-');
            if (parts.length > 1) {
                // Extraemos solo el número (última parte después del último guión)
                let numPart = parts[parts.length - 1];
                // Convertir a número y asegurarnos de que sea un número válido
                let nextNum = parseInt(numPart, 10) ;
                if (!isNaN(nextNum)) {
                    console.log("Actualizando numeración a:", nextNum);
                    sessionStorage.setItem('numeration', JSON.stringify(nextNum));
                }
            }
        } else {
            // Si es solo un número, lo guardamos directamente
            console.log("Actualizando numeración directamente a:", payload);
            sessionStorage.setItem('numeration', JSON.stringify(payload));
        }
    }

}
