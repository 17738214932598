import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import DataTables from "datatables.net"
import dataTableVue3 from "datatables.net-vue3"
import VueAxios from 'vue-axios'

//axios.defaults.baseURL = "http://friendlypos.test/api-web/v2"
axios.defaults.baseURL = "https://friendlypos.net/api-web/v2"

// Interceptor para manejar respuestas de error
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && (error.response.status === 401 || error.response.data.error === "Unauthenticated.")) {
      console.log('Error de autenticación detectado. Redirigiendo al login...');
      // Limpiar datos de sesión
      store.dispatch('getLogout');
      // Redirigir a la página de login
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

const app = createApp(App)

app.use(router)
    .use(VueAxios, axios)
    .use(store)
   // .use(DataTables,dataTableVue3)

app.mount('#app')
